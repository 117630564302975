
import { getBoard } from '@/api/myHomepageBoard';
import { IMyHomepageBoard, IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import HomepagePostForm from '@/components/homepage/board/form.vue';

@Component({
  name: 'HomepageBoardForm',
  components: {
    HomepagePostForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) detail!: IMyHomepageDetail

  mounted() {
    this.getBoard();
  }

  private board: IMyHomepageBoard | null = null;

  private getBoard() {
    getBoard(this.$route.params.boardUid).then((res) => {
      this.board = res.data;
    });
  }
}
