
import { IMyHomepageBoard, IMyHomepagePostAdd } from '@/types';
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { addHomepagePost, getHomepagePost, updateHomepagePost } from '@/api/myHomepagePost';
import { IAttachedFile } from '@/types/attachedFile';
import NoticeForm from './notice/form.vue';
import InquireForm from './inquire/form.vue';
import ProductForm from './product/form.vue';

@Component({
  name: 'HomepagePostForm',
  components: {
    NoticeForm,
    InquireForm,
    ProductForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IMyHomepageBoard

  mounted() {
    if (this.$route.params.postUid) {
      if (this.$route.name === 'HomepagePostReply') {
        getHomepagePost(this.$route.params.postUid, String(this.$route.query.password)).then((res) => {
          const targetPost = res.data;
          this.post = {
            boardUid: targetPost.boardUid,
            title: `답변:${targetPost.title}`,
            contents: '',
            writer: '',
            parentUid: targetPost.uid,
            password: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            option5: '',
            fileUidList: [],
            postPassword: '',
          };
        });
      } else {
        getHomepagePost(this.$route.params.postUid, String(this.$route.query.password)).then((res) => {
          this.post = res.data;
          this.post.fileUidList = [];
          res.data.fileList.forEach((f: any) => {
            this.post.fileUidList.push(f.pk.fileUid);
            this.fileList.push(f.file);
          });
        });
      }
    }
  }

  private post: IMyHomepagePostAdd = {
    boardUid: this.$route.params.boardUid,
    title: '',
    contents: '',
    writer: '',
    parentUid: null,
    password: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    fileUidList: [],
    postPassword: '',
  };

  private fileList: IAttachedFile[] = [];

  private getCancleRouter() {
    return {
      name: 'HomepageBoardIndex',
      params: {
        boardUid: this.$route.params.boardUid,
      },
    };
  }

  private handleClickSubmit() {
    (this.$refs.postForm as any).handleSubmit();
  }

  private handleSubmit(form: IMyHomepagePostAdd) {
    if (!this.$route.params.postUid) {
      addHomepagePost(form).then((res) => {
        alert('게시글이 작성되었습니다.');
        this.$router.push({ name: 'HomepagePostDetail', params: { boardUid: res.data.boardUid, postUid: res.data.uid }, query: { password: form.password } });
      });
    } else if (this.$route.name === 'HomepagePostReply') {
      addHomepagePost(form).then((res) => {
        alert('답글이 작성되었습니다.');
        this.$router.push({ name: 'HomepagePostDetail', params: { boardUid: res.data.boardUid, postUid: res.data.uid }, query: { password: form.password } });
      });
    } else {
      /* eslint-disable */
      form.postPassword = String(this.$route.query.password);
      updateHomepagePost(this.$route.params.postUid, form).then((res) => {
        alert('게시글이 수정되었습니다.');
        this.$router.push({ name: 'HomepagePostDetail', params: { boardUid: res.data.boardUid, postUid: res.data.uid }, query: { password: form.postPassword } });
      });
      /* eslint-enable */
    }
  }
}
