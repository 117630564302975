
import { uploadFile } from '@/api/attachedFile';
import { IMyHomepagePostAdd } from '@/types';
import { IAttachedFile } from '@/types/attachedFile';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Summernote from '@/components/summernote/index.vue';

@Component({
  name: 'HomepageBoardNoticeForm',
  components: {
    Summernote,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private post!: IMyHomepagePostAdd

  @Prop({ required: true }) private files!: IAttachedFile[]

  @Watch('post')
  private handleChangePost() {
    this.formData = {
      ...this.post,
    };
    this.fileList = this.files;
  }

  mounted() {
    this.formData = {
      ...this.post,
    };
    this.fileList = this.files;
  }

  private formData: IMyHomepagePostAdd = this.post;

  private fileList: IAttachedFile[] = [];

  private handleSubmit() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) this.$emit('submit', this.formData);
    });
  }

  private handleChangeFile(e: any) {
    if (this.fileList.length > 2) {
      alert('첨부파일은 3개까지 업로드 가능합니다.');
      return;
    }
    const file = e.target.files[0];
    if (5 * 1024 * 1024 < file.size) {
      alert('최대 5MB 까지 업로드 가능합니다.');
      return;
    }
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    uploadFile('client/my-homepage/post', formData).then((res) => {
      this.formData.fileUidList.push(res.data.uid);
      this.fileList.push(res.data);
    });
  }

  private handleClickRemoveFile(fileUid: string, index: number) {
    this.fileList.splice(index, 1);
    const fileUidIndex = this.formData.fileUidList.indexOf(fileUid);
    if (fileUidIndex > -1) this.formData.fileUidList.splice(fileUidIndex, 1);
  }

  private handleChangeContents(contents: string) {
    this.formData.contents = contents;
  }
}
